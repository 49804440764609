.home {
   padding: 65px 0px 60px;
}

.container {
   max-width: 1170px;
   margin: 0 auto;
   width: 100%;
   padding: 0px 15px;
}

.home__topimg {
   display: block;
   margin: 68px auto 102px;
   max-width: 100%;
}

