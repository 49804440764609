.instruction {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   max-width: 900px;
   width: 100%;
   grid-gap: 20px;
   margin: 40px auto 0px;
}

.instruction__image {
   max-width: 100%;
}

.instruction__head {
   background: #F7F7F7;
   width: 100%;
   display: block;
   padding: 12px;
   text-align: center;
   border-bottom: 2px solid #EDEDED;
}

.instruction__item {
   border: 2px solid #EDEDED;
   display: flex;
   flex-direction: column;
}

.instruction__body {
   padding: 0px 20px 35px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   height: 100%;
}

.instruction__text {
   font-size: 18px;
   text-align: center;
}

.instruction__image-wrapper {
   display: flex;
   align-items: center;
   padding: 50px 0px;
   height: 100%;
}

.instruction__image {
   display: block;
}

@media (max-width: 980px) {
   .instruction {
      grid-template-columns: 1fr 1fr;
   }

   .instruction__item:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
   }

   .instruction__item:nth-child(3) .instruction__image {
      max-width: 200px;
   }
}

@media (max-width: 680px) {
   .instruction {
      grid-template-columns: 1fr;
   }

   .instruction__item:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 2;
   }

   .instruction__item:nth-child(3) .instruction__image {
      max-width: 100%;
   }

   .instruction__item {
      max-width: 420px;
      margin: 0 auto;
   }
}