.preview__image-item-img {
   max-width: 100%;
}

.preview__inner {
   display: flex;
   align-items: center;
   justify-content: center;
}

.canvas__text {
   margin-bottom: 35px;
   text-align: center;
}

.canvas__instruction {
   font-size: 30px;
   line-height: 37px;
   margin-bottom: 4px;
}

.canvas__link {
   text-decoration: none;
   color: #1D30DC;
}

.canvas__button {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 26px;
   margin: 30px 0px 20px;
}

.canvas__button-item {
   padding: 33px 20px;
   font-family: inherit;
   color: inherit;
   font-size: 30px;
   line-height: 37px;
   border-radius: 50px;
   font-weight: 700;
   border: none;
   outline: none;
   cursor: pointer;
   transition: .2s;
}

.canvas__button-item:hover {
   transform: scale(.95);
}

.canvas__button-item--undress {
   background: #B0FD96;
}

.canvas__button-item--cancel {
   background: #FFB3B3;
}

@media (max-width: 570px) {
   .canvas__button {
      grid-template-columns: 1fr;
   }
}

@media (max-width: 570px) {
   .canvas__instruction {
      font-size: 25px;
      line-height: 29px;
   }
}

@media (max-width: 500px) {
   .canvas__instruction {
      font-size: 22px;
      line-height: 26px;
   }
}

@media (max-width: 400px) {
   .canvas__instruction {
      font-size: 20px;
      line-height: 24px;
   }
}