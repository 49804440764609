.item {
   padding: 35px 45px 25px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
}

.item.popular {
   width: 100%;
   padding: 45px 0px 25px;
}

.item__plan {
   font-weight: 700;
   margin-bottom: 17px;
   display: block;
   text-align: center;
}

.item.popular .item__plan {
   margin-bottom: 25px;
}

.item__price {
   font-weight: 700;
   font-size: 40px;
   line-height: 49px;
   display: block;
   margin-bottom: 17px;
   text-align: center;
}

.item.popular .item__price  {
   margin-bottom: 25px;
}

.item__priceforimg {
   display: block;
   text-align: center;
   margin-bottom: 36px;
}

.item.item.popular .item__priceforimg {
   margin-bottom: 40px;
}

.item__feature {
   display: flex;
   flex-direction: column;
   align-items: center;
   list-style: none;
   gap: 10px;
   margin-bottom: 35px;
}

.item.popular .item__feature {
   gap: 15px;
}

.item.popular .item__feature {
   margin-bottom: 40px;
}

.item__btn {
   padding: 14px 35px;
   text-transform: uppercase;
   font-weight: 700;
   margin: 0 auto;
   width: fit-content;
   display: block;
}


@media (max-width: 1250px) {
   .item:nth-child(2) {
      order: -1;
      grid-column-start: 1;
      grid-column-end: 3;
      max-width: 80%;
      margin: 0 auto 30px;
   }
}

@media (max-width: 830px) {
   .item:nth-child(2) {
      order: 0;
      grid-column-start: 1;
      grid-column-end: 2;
      max-width: 450px;
      margin: 0 auto;
   }

   .item {
      max-width: 450px;
      margin: 0 auto;
   }
}