.input__button {
   font-size: 30px;
   line-height: 37px;
   padding: 30px 100px;
}

.input__inner {
   box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
   border-radius: 50px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.input__left {
   display: flex;
   padding: 25px 50px;
   gap: 33px;
   align-items: center;
   width: 100%;
}

.input__input {
   font-size: 25px;
   line-height: 30px;
   width: 100%;
   display: block;
   max-width: 100%;
   border: none;
   outline: none;
}

.input__input::placeholder {
   color: #9D9D9D;
   font-family: inherit;
}


@media (max-width: 920px) {
   .input__button {
      padding: 30px 50px;
   }
   .input__left {
      padding: 25px 25px 25px 30px;
   }
}

@media (max-width: 780px) {
   .input__button {
      font-size: 25px;
   }
   .input__input {
      font-size: 22px;
   }
}

@media (max-width: 710px) {
   .input__inner {
      flex-direction: column;
   }
   .input__button {
      border-radius: 0px 0px 50px 50px;
      width: 100%;
   }

   .input__button:hover {
      transform: scale(1);
   }
}

@media (max-width: 500px) {
   .input__left {
      gap: 16px;
   }

   .input__icon {
      max-width: 25px;
   }

   .input__input {
      font-size: 20px;
   }

   .input__button {
      padding: 20px;
   }
}