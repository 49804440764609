.gallery {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-column-gap: 30px;
   grid-row-gap: 40px;
   margin-bottom: 76px;
}

.gallery__item {
   display: block;
   max-width: 100%;
   border-radius: 10px;
   object-fit: cover;
   width: 100%;
   aspect-ratio: 1 / 1;
}

@media (max-width: 1000px) {
   .gallery {
      grid-template-columns: 1fr 1fr;
   }
}

@media (max-width: 475px) {
   .gallery {
      grid-template-columns: 1fr;
   }
}