.pricing {
   padding: 65px 0px 105px;
}

.container {
   max-width: 1170px;
   width: 100%;
   margin: 0 auto;
   padding: 0px 15px;
}

.pricing__price {
   display: grid;
   grid-template-columns: 1fr 1.07fr 1fr;
   grid-gap: 43px;
   align-items: center;
}


@media (max-width: 1250px) {
   .pricing__price {
      grid-template-columns: 1fr 1fr;
   }
}

@media (max-width: 830px) {
   .pricing__price {
      grid-template-columns: 1fr;
   }
}