.footer {
   padding: 75px 0px 90px;
   position: relative;
}

.footer::before {
   content: '';
   position: absolute;
   top: 0;
   left: 50%;
   right: 0;
   transform: translateX(-50%);
   max-width: 1675px;
   width: 100%;
   height: 1px;
   background-color: rgba(0, 0, 0, 0.3);;
}

.footer__inner {
   max-width: 1212px;
   margin: 0 auto;
   width: 100%;
   padding: 0px 15px;
   display: flex;
   justify-content: space-between;
}

.footer__logo-text {
   font-weight: 700;
   font-size: 30px;
   line-height: 37px;
   margin-bottom: 6px;
}

.footer__menu-title {
   margin-bottom: 60px;
   font-weight: 700;
   font-size: 30px;
}

.footer__menu-list {
   display: flex;
   flex-direction: column;
   list-style: none;
   gap: 20px;
}

.footer__menu-link {
   color: inherit;
   text-decoration: none;
   font-size: 30px;
   position: relative;
}

.footer__menu-link::after {
   content: '';
   height: 2px;
   background-color: #000;
   position: absolute;
   left: 0;
   bottom: 0;
   width: 0;
   transition: .3s;
}

.footer__menu-link:hover::after {
   width: 100%;
}

@media (max-width: 1330px) {
   .footer__menu-link {
      font-size: 25px;
   }
}

@media (max-width: 570px) {
   .footer__inner {
      gap: 60px;
      flex-direction: column;
   }

   .footer__menu-link {
      font-size: 20px;
   }

   .footer__menu-title {
      margin-bottom: 20px;
   }
}