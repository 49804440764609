.modal__animation-enter {
   animation: show-modal-bg .5s forwards;
}

.modal__animation-exit {
   animation: show-modal-bg .5s forwards reverse;
}

.modal__animation-enter > div {
   animation: show-modal .5s forwards;
}

.modal__animation-exit > div {
   animation: show-modal .5s reverse;
}

@keyframes show-modal {
   0% {
      opacity: 0;
      transform: translateY(-200px) scale(.8);

   }
   50% {
      opacity: 1;
   }
   100% {
      transform: translateY(0) scale(1);
   }
}

@keyframes show-modal-bg {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.loaderfrompromt__animation-enter {
   animation: show-loaderfrompromt .5s forwards;
}

.loaderfrompromt__animation-exit {
   animation: show-loaderfrompromt .5s reverse;
}

@keyframes show-loaderfrompromt {
   0% {
      opacity: 0;
      transform: translateX(200px) scale(.8);
   }
   50% {
      opacity: 1;
   }
   100% {
      transform: translateX(0) scale(1);
   }
}