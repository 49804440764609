.meter {
   box-sizing: content-box;
   height: 25px;
   position: relative;
   margin-top: 10px;
   background: #6DD5ED;
   border-radius: 5px;
}

.meter>span {
   display: block;
   height: 100%;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
   background-color: #307DF6;
   /* background-image: ; */
   box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
   position: relative;
   overflow: hidden;
}

.meter>span:after,
.animate>span>span {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   background-image: linear-gradient(45deg,
         rgba(255, 255, 255, 0.2) 25%,
         transparent 25%,
         transparent 50%,
         rgba(255, 255, 255, 0.2) 50%,
         rgba(255, 255, 255, 0.2) 75%,
         transparent 75%,
         transparent);
   z-index: 1;
   background-size: 50px 50px;
   animation: move .5s linear infinite;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
   overflow: hidden;
}

.animate>span:after {
   display: none;
}

@keyframes move {
   0% {
      background-position: 0 0;
   }

   100% {
      background-position: 50px 0px;
   }
}

.loader {
   padding: 80px 0px;
}

.loader__animation {
   position: relative;
}

.loader__percent {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 18px;
   color: #fff;
   font-weight: 700;
}

.loader__text {
   text-align: center;
   font-size: 25px;
   display: block;
   font-weight: 700;
}