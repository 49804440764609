.nav {
   position: fixed;
   height: 100%;
   right: 0;
   top: 0;
   width: 320px;
   box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25);
   background: #252525;
   z-index: 20;
   transform: translateX(150%);
   transition: .2s;
}

.nav.active {
   transform: translateX(0);
}

.close {
   position: absolute;
   top: 10px;
   right: 10px;
   background: none;
   border: none;
   cursor: pointer;
   outline: none;
   margin: 0;
   padding: 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

.list {
   display: flex;
   flex-direction: column;
   list-style: none;
   padding-top: 60px;
}

.item {
   border-bottom: 1px solid #fff;
}

.item:last-child {
   border-bottom: none;
}

.link {
   display: block;
   padding: 20px;
   color: #fff;
   font-size: 25px;
   text-decoration: none;
   text-align: center;
}

.logout {
   border: none;
   background: none;
   width: 100%;
   font-family: inherit;
   cursor: pointer;
}

