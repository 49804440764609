.purchase {
   padding: 20px 0px 63px;
}

.container {
   max-width: 1400px;
   width: 100%;
   margin: 0 auto;
   padding: 0px 15px;
}

.purchase__header {
   padding: 32px 25px;
   background: #F99B2D;
   color: #fff;
   font-weight: 700;
   font-size: 25px;
   line-height: 30px;
}

.purchase__info {
   padding: 45px 25px;
   background: #F0F0F0;
   display: flex;
   flex-direction: column;
   gap: 40px;
   font-size: 25px;
   line-height: 30px;
}

.purchase__inner {
   display: flex;
   gap: 45px;
}

.purchase__aside {
   flex: 0 0 auto;
   max-width: 355px;
   width: 100%;
}

.purchase__asidemain {
   padding: 45px 37px 18px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.purchase__asidemain {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.purchase__price {
   margin-bottom: 18px;
}

.purchase__buy {
   margin-bottom: 23px;
   padding: 18px;
   display: block;
   font-weight: 700;
   font-size: 25px;
   line-height: 30px;
   width: 100%;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.counter {
   display: flex;
   gap: 10px;
   align-items: center;
   font-size: 25px;
   line-height: 30px;
   margin-bottom: 12px;
}

.counter__btn {
   background: none;
   border: none;
   outline: none;
   padding: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
   margin: 0;
   font-weight: 400;
   cursor: pointer;
   user-select: none;
}

.purchase__coupon {
   color: #929292;
   background: none;
   border: none;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
   cursor: pointer;
}

.purchase__price {
   font-size: 35px;
   line-height: 43px;
}

.purchase__aside-inner {
   margin-bottom: 20px;
}

.purchase__link {
   display: block;
   color: #2477D8;
   text-decoration: none;
   text-align: center;
}

@media (max-width: 1140px) {
   .purchase__inner {
      flex-direction: column-reverse;
   }

   .purchase__aside {
      margin: 0 auto;
      max-width: 450px;
   }
}

@media (max-width: 570px) {
   .purchase__info {
      font-size: 20px;
      line-height: 24px;
   }
}

@media (max-width: 400px) {
   .purchase__info {
      font-size: 18px;
      line-height: 22px;
      gap: 20px;
   }
}