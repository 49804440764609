.auth {
   padding: 65px 0px 150px;
}

.container {
   max-width: 540px;
   width: 100%;
   padding: 0px 15px;
   margin: 0 auto;
}

.auth__form {
   display: flex;
   flex-direction: column;
   gap: 16px;
   margin-bottom: 34px;
}

.auth__input {
   border: 1px solid rgba(0, 0, 0, 0.25);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
   font-size: 25px;
   line-height: 30px;
   padding: 23px 24px;
   outline: none;
}

.auth__input::placeholder {
   font-family: inherit;
   color: #000000;
   font-weight: 400;
}

.auth__btn {
   padding: 23px;
}

.auth__alternative {
   text-align: center;
}

.auth__alternative-link {
   color: #1433D9;
   text-decoration: none;
}