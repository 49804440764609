.title {
   text-align: center;
   font-weight: 700;
   font-size: 35px;
   line-height: 43px;
   margin-bottom: 42px;
}

@media (max-width: 460px) {
   .title {
      font-size: 30px;
   }
}