.premium__text {
   text-align: center;
   padding: 60px 0px 80px;
   font-size: 25px;
   line-height: 30px;
}

.premium__link {
   text-decoration: none;
   color: #4072F3;
}

@media (max-width: 460px) {
   .premium__text {
      font-size: 22px;
   }
}